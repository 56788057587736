import MyDocument from "../components/SolarPlantDetailPage/report";

function Download () {
    return (
        <div>
            <MyDocument />
        </div>
    )
}

export default Download;